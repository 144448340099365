<template>
    <footer>
        <div class="foot center">
            <div class="foot_xinxi">
                <h2>南昌为力人力资源服务有限公司</h2>
                <p v-for="item in contactus" :key="item.id"> {{item.name}}:&nbsp;&nbsp;{{item.content}}</p>
<!--                <p>联系电话： 0791-82203970</p>-->
<!--                <p>邮箱： 512763522@qq.com</p>-->
<!--                <p>QQ：512763522</p>-->
                <p>地址：江西省南昌市顺外路君嘉广场1号楼10楼　　备案号：
                    <a href="http://beian.miit.gov.cn" rel="external nofollow" target="_blank">赣ICP备18012679号</a></p>
                <p>版权所有 © 2018-2019南昌为力人力资源服务有限公司　　技术支持：
                    <a href="http://www.ncyateng.com" target="_blank">南昌雅腾信息科技有限公司</a></p>
            </div>
            <ul class="foot_list1">
                <li><router-link to="/">网站首页</router-link></li>
                <li><router-link to="TheStaffService">员工服务</router-link></li>
                <li><router-link to="recruitment">求职招聘</router-link></li>
            </ul>
            <ul class="foot_list2">
                <li><router-link to="theEnterpriseService">员工服务</router-link></li>
                <li><router-link to="News">新闻资讯</router-link></li>
                <li><router-link to="ContactUs">联系我们</router-link></li>
            </ul>
            <img src="http://www.jxwlrl.com/images/ewm.jpg">
        </div>
    </footer>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import axios from 'axios'

    export default {
        name: "MyBottom",
        data() {
            return {
                contactus: [],
            }
        },
        mounted() {
            this.contactuslist()
        },
        methods: {
            // contactuslist() {
            //     axios({
            //         url: "/api/receptionPhp/ContactUs/message.php"
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.contactus = res.data.data;
            //     })
            // }
            contactuslist() {
                let params = {}
                list(params,'ContactUs').then((res) => {
                    // console.log(res.data.data);
                    this.contactus = res.data.data;
                })
            }
        },

    }
</script>

<style scoped>

</style>