<template>
    <div>
        <div class="banner2">

        </div>
        <div class="main center">
            <div class="main_tit">
                <h2>下载中心</h2>
                <img src="http://www.jxwlrl.com/images/jian.png">
                <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
            </div>
            <div class="xzzx_list">
                <ul>

                    <li>
                        <dd>名称</dd>
                        <dd>发布时间</dd>
                        <dd>下载</dd>
                    </li>
                    <li v-for="item in mes" :key="item.id">
                        <dd>{{item.title}}</dd>
                        <dd>{{item.ctime|today(item.ctime)}}</dd>
                        <dd><a :href="item.content" :download="item.title">立即下载</a></dd>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import time from "../../public/time/time";
    import axios from "axios";

    export default {
        name: "DownLoad",
        data() {
            return {
                mes: [],
                hh:'DownLoad'
            }
        },
        filters: {
            today: function (value) {
                return time.formtimestamp("Y-m-d", value);
            },
        },
        methods: {
            // message() {
            //     axios({
            //         url: "/api/receptionPhp/DownLoad/message.php",
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.mes = res.data.data;
            //     })
            // }

            message() {
                let params ={}
                list(params,this.hh).then((res) => {
                    // console.log(res.data.data);
                    this.mes = res.data.data;
                })
            }
        },
        mounted() {
            this.message();
        }
    }
</script>

<style scoped>

</style>