<template>
    <div>
        <div class="banner2">
        </div>
        <div class="main center">
            <div class="main_tit">
                <h2>求职招聘</h2>
                <img src="http://www.jxwlrl.com/images/jian.png">
                <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
                <ul>
                    <li v-for="item in sortname" :key="item.id" :class="sort==item.id?'on':''">
                        <router-link :to="{path:'/Recruitment',query:{sort:item.id}}">{{item.sortname}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="join_us" :style="{display:sort==39?'block':'none'}">
                <ul id="xingxi">
                    <li>
                        <dd width="300">招聘岗位</dd>
                        <dd width="180">招聘部门</dd>
                        <dd width="140">学历要求</dd>
                        <dd width="150">预计薪资</dd>
                        <dd width="140">聘人数</dd>
                        <dd width="170">发布时间</dd>
                    </li>
                    <li class="bg1" v-for="item in recruitmentinformationmes" :key="item.id" @click="show(item.id)">
                        <dd>{{item.jobtitle}}</dd>
                        <dd>{{item.sortname}}</dd>
                        <dd>{{item.degreerequired}}</dd>
                        <dd>{{item.salary}}</dd>
                        <dd>{{item.number}}</dd>
                        <dd>{{item.time}}</dd>
                        <img :src="id==item.id?tupian:'http://www.jxwlrl.com/images/xia.png'" alt="无图">
                        <div class="clr"></div>
                        <ul class="xiangxi" v-show="id==item.id?isshow:''">
                            <li>
                                <h5>岗位职责：</h5>
                                <p>{{item.responsibility}}</p>
                            </li>
                            <br>
                            <li>
                                <h5>岗位要求：</h5>
                                <p v-html="item.jobrequirements">
                                </p>
                            </li>
                            <br>
                            <li>
                                <h5>福利待遇：</h5>
                                <span>{{item.welfaretreatment}}</span>
                            </li>
                            <router-link :to="{path:'/RecruitmentInformation',query:{sort:39,id:item.id}}">详细请点这里
                            </router-link>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="qzzp_ms_list">
                <ul>
                    <li v-for="item in mes" :key="item.id" :style="{display:sort==item.sort?'block':'none'}">
                        <div>
                            <b>{{item.ctime| todate(item.ctime)}}</b>
                            <span>{{item.ctime| tomonth(item.ctime)}}</span>
                        </div>
                        <router-link :to="{path:'/Recruitmentdetails',query: {sort:item.sort,id:item.id}}">
                            <h2>{{item.title}}</h2>
                            <p v-html="item.licontent"></p>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="qzzp_qz clearFix" :style="{display:sort==53?'block':'none'}">
                <img src="http://www.jxwlrl.com/images/zxqz.jpg" alt="">
                <div class="qzzp_qz_form">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="ruleForm.sex">
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="电话号码" prop="tel">
                            <el-input v-model="ruleForm.tel"></el-input>
                        </el-form-item>
                        <el-form-item label="意向工作" prop="intention">
                            <el-input v-model="ruleForm.intention" class="content"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!--        <div class="hei"></div>-->
            <div class="paging" :style="{display:sort==53?'none':''}">
                <div style="text-align: center">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import axios from "axios";
    import time from "../../public/time/time";
    import common from "@/mixins/common";
    import $ from "jquery";

    export default {
        name: "Recruitment",
        mixins: [common],
        data() {
            return {
                parentid: 32,
                sortname: [],
                sort: 39,
                classname: "OnlineJob",
                mes: [],
                recruitmentinformationmes: [],
                id: "",
                isshow: false,
                tupian: "http://www.jxwlrl.com/images/xia.png",
            }
        },
        filters: {
            todate: function (value) {
                return time.formtimestamp('d', value);
            },
            tomonth: function (value) {
                return time.formtimestamp("Y-m", value);
            },
        },
        watch: {
            $route() {
                if (this.$route.query.page) {

                } else {
                    this.page = 1
                }
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);

                if (this.$route.query.sort) {
                    this.sort = this.$route.query.sort;
                }

                if(this.sort==39){
                    this.getRecruitmentInformation()
                }else{
                    this.handleData();
                }








            }
        },
        methods: {
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {
                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
            // getRecruitmentInformation() {
            //     // if (this.$route.query.sort) {
            //     //     this.sort = this.$route.query.sort;
            //     // }
            //     axios({
            //         url: "/api/receptionPhp/RecruitmentInformation/message.php",
            //         params: {limit: 7, page: this.page}
            //     }).then((res) => {
            //         // console.log(res.data);
            //         this.recruitmentinformationmes = res.data.data;
            //         this.total = Number(res.data.count);
            //     })
            // },
            getRecruitmentInformation() {
                // if (this.$route.query.sort) {
                //     this.sort = this.$route.query.sort;
                // }
                let params = {limit: 7, page: this.page}
                list(params,'RecruitmentInformation').then((res) => {
                    // console.log(res.data);
                    this.recruitmentinformationmes = res.data.data;
                    this.total = Number(res.data.count);
                })
            },
            handleData() {
                if (this.$route.query.sort) {
                    this.sort = this.$route.query.sort;
                }
                // axios({
                //     url: "/api/receptionPhp/Recruitment/message.php",
                //     params: {sort: this.sort, page: this.page, limit: this.size,},
                // }).then((res) => {
                //     console.log(res.data);
                //     this.mes = res.data.data;
                //     this.total = Number(res.data.count);
                // })
                let params = {sort: this.sort, page: this.page, limit: this.size}
                list(params,'Recruitment').then((res) => {
                    console.log(res.data);
                    this.mes = res.data.data;
                    this.total = Number(res.data.count);
                })
            },
            show(id) {
                // console.log(e)
                this.id = id;
                if (this.isshow == false && this.tupian == "http://www.jxwlrl.com/images/xia.png") {
                    this.isshow = true;
                    this.tupian = "http://www.jxwlrl.com/images/shang.png";
                } else {
                    this.isshow = false;
                    this.tupian = "http://www.jxwlrl.com/images/xia.png";
                }
            },
        },

        mounted() {
            this.getSortName();
            if(this.sort==39){
                this.getRecruitmentInformation();
            }else{
                this.handleData();
            }


        },
        created() {
            if (this.$route.query.page) {
                this.page = this.$route.query.page;
            }
            // this.page=1;


        },
    }
</script>

<style scoped>

</style>