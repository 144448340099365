<template>
    <div>
        <div class="banner1">
        </div>
        <div class="main center">
            <div class="main_tit">
                <h2>关于我们</h2>
                <img src="http://www.jxwlrl.com/images/jian.png">
                <p>坚持开源，不断创新</p>
                <ul>
                    <li v-for="item in sortname" :key="item.id" :class="sort==item.id?'on':''">
                        <router-link :to="{path:'/AboutUs',query:{sort:item.id}}">{{item.sortname}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="main_gcjj" :style="{display:(sort==14)?'block':'none'}">
                <img :src="mes.pic" alt="">
                <div>
                    <h1><span>{{mes.title}}</span></h1>
                    <p></p>
                    <div style="text-align:left;">
                        <span v-html="mes.content">
<!--                            南昌为力人力资源服务有限公司，具有独立的法人资格，是国内较早涉足人才派遣业务的人才中介机构。公司凭强大的招聘平台、快捷的网络、依托完整的档案管理系统，以全新的理念和创新的精神打造自己的服务品牌，并致力于建设全方位提供人才派遣服务的专业机构。目前本公司服务涵盖机关、事业、金融、高校、医疗、研究院所、信息IT、服务行业、各类企业，以及驻南昌机构等各类单位。欢迎社会各类性质的企事业单位与我公司合作。在我们的竭诚服务中，为力人力资源服务有限公司为企业提供专业的服务外包、招聘猎头、员工福利和人事服务等相关人力资源解决方案。-->
                        </span>
                    </div>
                </div>
            </div>
            <div class="main_qywh clearFix" :style="{display:(sort==13)?'block':'none'}">
                <h1>{{mes.title}}<span></span></h1>
                <ul v-html="mes.content">

                </ul>
                <img :src="mes.pic">
            </div>

            <div class="main_ryzz" :style="{display:(sort==31)?'block':'none'}">
                <h1>荣誉资质<span></span>
                    <div class="clr"></div>
                </h1>

                <div class="rongyu_box">
                    <!--                    <div class="swiper">-->
                    <!--                        <div class="swiper-wrapper">-->
                    <!--                            <div class="swiper-slide" v-for="item in mes">-->
                    <!--                                <img :src="item.pic" alt="">-->
                    <!--                            </div>-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide" v-for="item in mes" :key="item.id">&ndash;&gt;-->
                    <!--&lt;!&ndash;                                <img :src="item.pic" alt="">&ndash;&gt;-->
                    <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                    <!--                            <div class="swiper-slide" v-for="item in vic" >-->
                    <!--                                <img :src="item.pic" alt="">-->
                    <!--                            </div>-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">1</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">2</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">3</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">4</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">5</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">6</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <div class="swiper-slide">7</div>&ndash;&gt;-->

                    <!--                        </div>-->
                    <!--                        <div class="swiper-button-next"></div>-->
                    <!--                        <div class="swiper-button-prev"></div>-->
                    <!--                    </div>-->
                    <el-carousel :interval="4000" type="card" height="200px" indicator-position="none">
                        <el-carousel-item v-for="item in mes" :key="item.id">
                            <h3 class="medium"><img :src="item.pic" alt=""></h3>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {category, list} from '../api/aboutUs'
    import $ from "jquery";
    import qs from 'qs'
    import common from "@/mixins/common";
    import axios from 'axios'
    import Swiper from 'swiper/bundle';
    import 'swiper/css/bundle';

    export default {
        name: "AboutUs",
        data() {
            return {
                parentid: 12,
                sortname: [],
                sort: 13,
                mes: [],
                // directionmes:"",
                vic: [],
                hh: 'AboutUs'
            }
        },
        watch: {
            $route() {
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this.message();
            }
        },
        mounted() {
            const swiper = new Swiper('.swiper', {
                slidesPerView: 3,
                // loopedSlides: 6,
                loop: true,
                autoplay: true,//等同于以下设置
                direction: 'horizontal',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // slidesOffsetBefore : 100,
                // on: {
                //     resize: function () {
                //          swiper.changeDirection(this.directionmes);
                //         // console.log( swiper.changeDirection(this.directionmes))
                //     },
                // },
            });
            this.getSortName();
            this.message();
            // this.getDirection();
        },
        methods: {
            // xixi(){
            //     swiper.changeDirection(this.directionmes);
            // },
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {
                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },

            getDirection() {
                var windowWidth = window.innerWidth;
                var direction = window.innerWidth <= 760 ? 'vertical' : 'horizontal';
                // console.log(".-..---------------",direction);
                // return direction;
                this.directionmes = direction;
            },

            message() {
                if (this.$route.query.sort) {
                    this.sort = this.$route.query.sort;
                }
                // axios({
                //     url: "/api/receptionPhp/AboutUs/message.php",
                //     params: {sort: this.sort},
                // }).then((res) => {
                //     // console.log(res.data.data);
                //     if (this.sort != 31) {
                //         this.mes = res.data.data["0"];
                //     } else {
                //         this.mes = res.data.data;
                //         this.vic=res.data.data;
                //     }
                // })
                const params = {sort: this.sort}
                list(params, this.hh).then((res) => {
                    // console.log(res.data.data);
                    if (this.sort != 31) {
                        this.mes = res.data.data["0"];
                    } else {
                        this.mes = res.data.data;
                        this.vic = res.data.data;
                    }
                })


            }
        }
    }
</script>

<style scoped>
    /*@import "../../node_modules/swiper/swiper-bundle.min.css";*/

    .rongyu_box {
        /*position: relative;*/
        padding-top: 50px;
        height: 200px;
        text-align: center;
        width: 1200px;
    }

    /*.el-carousel__item:nth-child(2n) {*/
    /*    background-color: #99a9bf;*/
    /*}*/

    /*.el-carousel__item:nth-child(2n+1) {*/
    /*    background-color: #d3dce6;*/
    /*}*/

    /*.rongyu_box {*/
    /*    !*background: #eee;*!*/
    /*    !*font-family: Helvetica Neue, Helvetica, Arial, sans-serif;*!*/
    /*    !*font-size: 14px;*!*/
    /*    !*color: #000;*!*/
    /*    margin: 0;*/
    /*    padding: 0;*/
    /*}*/

    .swiper {
        width: 1200px;
        height: 300px;
    }

    /*.swiper-slide {*/
    /*    text-align: center;*/
    /*    font-size: 18px;*/
    /*    background: #fff;*/
    /*    width: 400px;*/

    /*    !* Center slide text vertically *!*/
    /*    display: -webkit-box;*/
    /*    display: -ms-flexbox;*/
    /*    display: -webkit-flex;*/
    /*    display: flex;*/
    /*    -webkit-box-pack: center;*/
    /*    -ms-flex-pack: center;*/
    /*    -webkit-justify-content: center;*/
    /*    justify-content: center;*/
    /*    -webkit-box-align: center;*/
    /*    -ms-flex-align: center;*/
    /*    -webkit-align-items: center;*/
    /*    align-items: center;*/
    /*}*/

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    /*.swiper-button-next {*/
    /*    right: 20px;*/
    /*    transform: rotate(360deg);*/
    /*}*/

    /*.swiper-button-prev {*/
    /*    left: 20px;*/
    /*    transform: rotate(360deg);*/
    /*}*/

</style>

