<template>
    <div>
        <div class="banner2">
        </div>
        <div class="main center">
            <div class="main_tit">
                <h2>员工服务</h2>
                <img src="http://www.jxwlrl.com/images/jian.png">
                <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
                <ul>
                    <li v-for="item in sortname" :class="sort==item.id?'on':''">
                        <router-link :to="{path:'/TheStaffService',query:{sort:item.id}}">{{item.sortname}}</router-link>
                    </li>
<!--                    <li><a href="">入职手续</a></li>-->
<!--                    <li><a href="">离职手续</a></li>-->
<!--                    <li><a href="">办事指南</a></li>-->
<!--                    <li><a href="">社保基金</a></li>-->
<!--                    <li><a href="">通告专栏</a></li>-->
<!--                    <li><a href="">学历提升</a></li>-->

                </ul>
            </div>
            <div class="qzzp_ms_list">
                <ul>
                    <li v-for="item in mes" :key="item.id">
                        <div>
                            <b>{{item.ctime|todate(item.ctime)}}</b>
                            <span>{{item.ctime|tomonth(item.ctime)}}</span>

                        </div>
                            <router-link :to="{path:'/TheStaffServiceDetails',query:{sort:item.sort,id:item.id}}">
                                <h2>{{item.title}}</h2>
                                <p>{{item.licontent}}</p>
                            </router-link>

                    </li>
                </ul>
            </div>
            <div class="paging">
                <div style="text-align: center">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
<!--                <a>共 20 页, 每页显示5条</a> <b>1</b><a onclick="this.blur()" href="/news?page=2">2</a><a onclick="this.blur()"-->
<!--                                                                                                   href="/news?page=3">3</a><a-->
<!--                    onclick="this.blur()" href="/news?page=4">4</a><a onclick="this.blur()" href="/news?page=5">5</a><a-->
<!--                    onclick="this.blur()" href="/news?page=6">6</a><a onclick="this.blur()" href="/news?page=7">7</a><a-->
<!--                    onclick="this.blur()" href="/news?page=8">8</a><a onclick="this.blur()" href="/news?page=9">9</a><a-->
<!--                    onclick="this.blur()" href="/news?page=10">10</a><a href="/news?page=2" onclick="this.blur()"-->
<!--                                                                        class="next">下一页</a><a onclick="this.blur()"-->
<!--                                                                                               href="/news?page=20">末页</a>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {category, list,longlist} from '../api/aboutUs'
    import time from "../../public/time/time";
    import axios from "axios";
    import common from "@/mixins/common";

    export default {
        name: "TheStaffService",
        mixins:[common],
        data() {
            return {
                parentid: 4,
                sortname: [],
                sort: 5,
                mes: [],
            }
        },
        filters: {
            todate: function (value) {
                return time.formtimestamp('d', value);
            },
            tomonth: function (value) {
                return time.formtimestamp("Y-m", value);
            },
        },
        watch: {
            $route() {

                if(this.$route.query.page){

                }else{
                    this.page=1
                }
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this. handleData();
            }
        },
        methods: {
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },

            getSortName() {
                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
             handleData() {
                if (this.$route.query.sort) {
                    this.sort = this.$route.query.sort;
                }
                // axios({
                //     url: "/api/receptionPhp/TheStaffService/message.php",
                //     params: {sort: this.sort},
                // }).then((res) => {
                //     // console.log(res.data.data);
                //     this.mes = res.data.data;
                //     this.total = Number(res.data.count);
                // })

                 let params = {sort: this.sort}
                list(params,'TheStaffService').then((res) => {
                     // console.log(res.data.data);
                     this.mes = res.data.data;
                     this.total = Number(res.data.count);
                 })
            }
        },
        mounted() {
            this.getSortName();
            this. handleData();
        },
        created() {

            if (this.$route.query.page) {
                this.page = this.$route.query.page;
            }
            // this.page=1;


        },
    }
</script>

<style scoped>

</style>