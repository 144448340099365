<template>
    <div id="app">
        <body>
        <MyTop></MyTop>
        <router-view></router-view>
        <MyBottom></MyBottom>
        </body>
    </div>
</template>
<script>
    import MyTop from "@/components/MyTop";
    import MyBottom from "@/components/MyBottom";

    export default {
        components: {
            MyTop,
            MyBottom,
        },
    };
</script>
<style scoped>
    @import '../public/css/index.css';
</style>
