import axios from "axios";
import qs from "qs";
import merge from "webpack-merge";

export default {
    data() {
        return {
            ruleForm: {},
            tableData: [],
            page: 1,
            // newpage:'',
            size: 5,
            total: 0,
            // parentid: "",
            rules: {
                name: [
                    {required: true, message: '请填写姓名 ', trigger: 'blur'},
                ],
                intention:[
                    {required: true, message: '请填写意向工作 ', trigger: 'blur'},
                ],
                sex: [
                    {required: true, message: '请选择性别 ', trigger: 'blur'},
                ],
                keyword:[
                    {required: true, message: '输入关键词 ', trigger: 'blur'},
                ],
                tel: [
                    {required: true, message: '请填写电话 ', trigger: 'blur'},
                    {message: '电话格式不正确',trigger: 'blur',validator:this.rulescontactTelephone},
                ],
            }
        }
    },
    mounted() {
    },
    methods: {
        //电话验证开始
        rulescontactTelephone: function (rule, value, callback) {
            if (value==="") {
                callback(new Error('不允许为空'))
            } else {
                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(value)) {
                    callback(new Error('手机号格式不正确'))
                } else {
                    callback()
                }
            }
        },
        handleSizeChange(val) {
            this.size = val;
            this.page = 1;

            if(this.sort==39){
                this.getRecruitmentInformation()
            }else{
                this.handleData();
            }


        },
        handleCurrentChange(val) {
            // console.log(val)
            this.page = val;
            this.$router.push({    //新增一个参数
                query:merge(this.$route.query,{'page':val})
            })
            // if(!this.$route.query.page){
            //     this.$router.push({    //新增一个参数
            //         query:merge(this.$route.query,{'page':1})
            //     })
            // }
            // this.newpage = val;

            if(this.sort==39){
                this.getRecruitmentInformation()
            }else{
                this.handleData();
            }


        },
        //提交及保存开始
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                // console.log(valid);
                if (valid) {
                    // alert('submit!');
                    this.save();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        save() {
            // console.log("打印点击保存时的类别-----", typeof this.ruleForm.sort);
            // console.log("打印点击保存时的类别-----", this.ruleForm.sort);
            let data = qs.stringify(this.ruleForm);
            // console.log(data);

            axios({
                method: "post",//这里是method，请注意
                url: "http://www.wlrlphp.ganjiahandsome.online/" + this.classname + "/save.php",
                data: data
            }).then(res => {
                // console.log(res);
                if (res.data.code == 0) {
                    this.$message({
                        message: "成功",
                        type: 'success'
                    });
                    this.dialogVisible = false;
                    this.handleData();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //提交及保存结束
    },
}