<template>
    <div>
        <div class="banner">
            <!--            <div class="swiper mySwiper">-->
            <!--                <div class="swiper-wrapper">-->
            <!--                    <div class="swiper-slide"><img src="../../public/images/s_banner1.jpg" alt=""></div>-->
            <!--                    <div class="swiper-slide"><img src="../../public/images/s_banner2.jpg"/></div>-->
            <!--                    <div class="swiper-slide"><img src="../../public/images/s_banner3.jpg"/></div>-->
            <!--                </div>-->
            <!--                &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;-->
            <!--                <div class="swiper-button-next"></div>-->
            <!--                <div class="swiper-button-prev"></div>-->
            <!--                &lt;!&ndash; 如果需要滚动条 &ndash;&gt;-->
            <!--                <div class="swiper-scrollbar"></div>-->
            <!--            </div>-->
            <!-- Slider main container -->
            <div class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img src="../../public/images/s_banner1.jpg" alt=""/>
                    </div>
                    <div class="swiper-slide">
                        <img src="../../public/images/s_banner2.jpg"/>
                    </div>
                    <div class="swiper-slide">
                        <img src="../../public/images/s_banner3.jpg"/>
                    </div>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination"></div>

                <!-- If we need navigation buttons -->
                <!-- <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div> -->

                <!-- If we need scrollbar -->
                <!--                <div class="swiper-scrollbar"></div>-->
            </div>
        </div>
        <div class="midden">
            <div class="midden_t clearFix">
                <div class="news fl">
                    <div class="common">
                        <ul class="clearFix">
                            <li @mouseover="changeSort(10)">公司动态</li>
                            <li @mouseover="changeSort(11)">行业快讯</li>
                            <router-link to="News?sort=11">更多>></router-link>
                            <!--                            <a href="News.vue?sort=11">更多>></a>-->
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li v-for="item in newsmes" :key="item.id" class="content">
                                <router-link :to="{ path: 'Newsdetails', query: {sort: item.sort , id: item.id } }">
                                    <img :src="item.pic" alt=""/>
                                    <h2>{{ item.title }}</h2>
                                    <p v-html="item.licontent"></p>
                                </router-link>
                            </li>
                            <!--                            <li class="content">-->
                            <!--                                <img src="../../public/images/news_1.jpg" alt="">-->
                            <!--                                <h2>2020年南昌市清理整顿人力资源</h2>-->
                            <!--                                <p>招聘办公室岗1名，会计岗1名。...</p>-->
                            <!--                            </li>-->
                            <!--                            <li class="content">-->
                            <!--                                <img src="../../public/images/news_1.jpg" alt="">-->
                            <!--                                <h2>2020年南昌市清理整顿人力资源</h2>-->
                            <!--                                <p>招聘办公室岗1名，会计岗1名。...</p>-->
                            <!--                            </li>-->
                            <!--                            <li class="content">-->
                            <!--                                <img src="../../public/images/news_1.jpg" alt="">-->
                            <!--                                <h2>2020年南昌市清理整顿人力资源</h2>-->
                            <!--                                <p>招聘办公室岗1名，会计岗1名。...</p>-->
                            <!--                            </li>-->
                        </ul>
                    </div>
                </div>
                <div class="news_policies fl">
                    <div class="common_one">
                        <ul class="clearFix">
                            <li>政策法规</li>
                            <router-link to="News?sort=21">更多>></router-link>
                        </ul>
                    </div>
                    <div class="content">
                        <ul>
                            <li v-for="item in falvfaguimes" :key="item.id">
                                <router-link :to="{ path: 'News', query: { id: item.id } }">{{
                                    item.title
                                    }}
                                </router-link>
                            </li>
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                            <!--                            <li><a href="">五险一金将建“黑名单”制度 这些行为会被惩戒</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="others">
                    <ul class="clearFix">
                        <li>
                            <a href=""><h2>社保查询</h2></a>
                        </li>
                        <li>
                            <a href=""><h2>公积金查询</h2></a>
                        </li>
                        <li>
                            <a href=""><h2>学历提升</h2></a>
                        </li>
                        <li>
                            <a href=""><h2>网上报名</h2></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="recruitment_all">
            <div class="recruitment">
                <h2 class="same_h">招聘求职</h2>
                <p class="same_p">
                    为力人力资源服务有限公司为企业提供专业的服务外包、招聘猎头、员工福利和
                    <br/>人事服务等相关人力资源解决方案
                </p>
            </div>
            <div class="recruitment_bottom clearFix">
                <div class="zpxx fl">
                    <div class="common_one">
                        <ul class="clearFix">
                            <li>招聘信息</li>
                            <!--                            <a href="">更多>></a>-->
                            <router-link :to="{ path: '/Recruitment', query: { sort: 39 } }"
                            >更多>>
                            </router-link
                            >
                        </ul>
                    </div>
                    <div class="height"></div>
                    <div class="zpxx_bottom clearFix">
                        <img src="../../public/images/zpxx.jpg" alt=""/>
                        <ul class="fl">
                            <li v-for="item in zpxxmes" :key="item.id">
                                <router-link
                                        :to="{ path: '/Recruitment', query: { id: item.id } }"
                                >
                                    <h6>{{ item.recruiters }}</h6>
                                    <p>{{ item.jobtitle }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="fl zxqz clearFix">
                    <div class="common_one">
                        <ul class="clearFix">
                            <li>在线求职</li>
                        </ul>
                    </div>
                    <div style="height: 15px"></div>
                    <el-form
                            :model="ruleForm"
                            :rules="rules"
                            ref="ruleForm"
                            label-width="100px"
                            class="demo-ruleForm"
                    >
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="ruleForm.sex">
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="电话号码" prop="tel">
                            <el-input v-model="ruleForm.tel"></el-input>
                        </el-form-item>
                        <el-form-item label="意向工作" prop="intention">
                            <el-input v-model="ruleForm.intention"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="coreServices_all">
            <div class="coreServices">
                <h2 class="same_h">核心服务</h2>
                <p class="same_p">
                    为力人力资源服务有限公司为企业提供专业的服务外包、招聘猎头、员工福利和<br/>人事服务等相关人力资源解决方案
                </p>
            </div>
            <div class="coreServices_bottom">
                <ul class="clearFix">
                    <li>
                        <router-link to="TheEnterpriseService?sort=8">
                            <img src="../../public/images/laowupaiqian.jpg" alt=""/>
                            <div>
                                <h3>{{ lwpqmes.title }}</h3>
                                <p v-html="lwpqmes.content"></p>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="TheEnterpriseService?sort=9">
                            <img src="http://www.jxwlrl.com/images/hxff2.jpg" alt=""/>
                            <div>
                                <h3>{{ fwwbmes.title }}</h3>
                                <p v-html="fwwbmes.content"></p>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="TheEnterpriseService?sort=26">
                            <img src="http://www.jxwlrl.com/images/hxff3.jpg" alt=""/>
                            <div>
                                <h3>{{ djsbmes.title }}</h3>
                                <p v-html="djsbmes.content"></p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="yqlj clearFix">
            <div>友情链接 ：</div>
            <a href="">南昌为力人力资源有限公司</a><span></span>
            <a href="http://www.ncyateng.com/" target="_blank">南昌雅腾科技有限公司</a
            ><span></span>
            <a href="http://rst.jiangxi.gov.cn/" target="_blank">江西省人社厅</a
            ><span></span>
            <a href="https://www.jxrcw.com/" target="_blank">江西人才网</a
            ><span></span>
            <a href="http://rsj.nc.gov.cn/" target="_blank">南昌市人保局</a
            ><span></span>
            <a href="">南昌市职业技能培训中心</a>
        </div>
    </div>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import $ from "jquery";
    import qs from "qs";
    import common from "@/mixins/common";
    import Swiper from "swiper/bundle";
    import axios from "axios";
    import "swiper/css/bundle";

    export default {
        name: "HomeView",
        mixins: [common],
        // components: {
        //     Swiper,
        //     SwiperSlide,
        // },
        data() {
            return {
                classname: "OnlineJob",
                sort: 10,
                newsmes: [],
                falvfaguimes: [],
                fwwbmes: [],
                lwpqmes: [],
                djsbmes: [],
                zpxxmes: [],
                hh:'News'
            };
        },
        // setup() {
        //     const onSwiper = (swiper) => {
        //         console.log(swiper);
        //     };
        //     const onSlideChange = () => {
        //         console.log('slide change');
        //     };
        //     return {
        //         onSwiper,
        //         onSlideChange,
        //         modules: [Navigation, Pagination, Scrollbar, A11y],
        //     };
        // },
        mounted() {
            const swiper = new Swiper(".swiper", {
                // Optional parameters
                // direction: 'vertical',
                loop: true,
                effect: "fade",
                autoplay: true, //等同于以下设置

                // If we need pagination
                pagination: {
                    el: ".swiper-pagination",
                },

                // Navigation arrows
                //   navigation: {
                //     nextEl: ".swiper-button-next",
                //     prevEl: ".swiper-button-prev",
                //   },

                // And if we need scrollbar
                // scrollbar: {
                //     el: '.swiper-scrollbar',
                // },
            });

            this.newsmessage();
            this.falvfagui();
            this.fwwb();
            this.lwpq();
            this.djsb();
            this.zpxx();
        },
        methods: {
            // newsmessage() {
            //   // console.log(this.sort);
            //   axios({
            //     url: "http://www.wlrlphp.ganjiahandsome.online/receptionPhp/News/message.php",
            //     params: { sort: this.sort, limit: 3 },
            //   }).then((res) => {
            //       console.log(res)
            //     this.newsmes = res.data.data;
            //   });
            // },
            newsmessage() {
                // console.log(this.sort);
                let params ={sort: this.sort, limit: 3}
                list(params,this.hh).then((res) => {
                    console.log(res)
                    this.newsmes = res.data.data;
                });
            },
            changeSort(e) {
                if (e == 10) {
                    // console.log(e == 10)
                    this.sort = "10";
                } else if (e == 11) {
                    this.sort = "11";
                }
                this.newsmessage();
            },
            // falvfagui() {
            //     axios({
            //         url: "/api/receptionPhp/News/message.php",
            //         params: {sort: 21, limit: 7},
            //     }).then((res) => {
            //         this.falvfaguimes = res.data.data;
            //     });
            // },
            falvfagui() {
                let params ={sort: 21, limit: 7}
                list(params,this.hh).then((res) => {
                    this.falvfaguimes = res.data.data;
                });
            },
            // fwwb() {
            //     axios({
            //         url: "/api/receptionPhp/TheEnterpriseService/message.php",
            //         params: {sort: 9},
            //     }).then((res) => {
            //         this.fwwbmes = res.data.data[0];
            //     });
            // },
            fwwb() {
                let params ={sort: 9}
                list(params,'TheEnterpriseService').then((res) => {
                    this.fwwbmes = res.data.data[0];
                });
            },
            // lwpq() {
            //     axios({
            //         url: "/api/receptionPhp/TheEnterpriseService/message.php",
            //         params: {sort: 8},
            //     }).then((res) => {
            //         this.lwpqmes = res.data.data[0];
            //     });
            // },
            lwpq() {
                let params ={sort: 8}
                list(params,"TheEnterpriseService").then((res) => {
                    this.lwpqmes = res.data.data[0];
                });
            },
            // djsb() {
            //     axios({
            //         url: "/api/receptionPhp/TheEnterpriseService/message.php",
            //         params: {sort: 26},
            //     }).then((res) => {
            //         this.djsbmes = res.data.data[0];
            //     });
            // },
            djsb() {
                let params ={sort: 26}
                list(params,"TheEnterpriseService").then((res) => {
                    this.djsbmes = res.data.data[0];
                });
            },
            // zpxx() {
            //     axios({
            //         url: "/api/receptionPhp/RecruitmentInformation/message.php",
            //         params: {limit: 7},
            //     }).then((res) => {
            //         // console.log(res.data);
            //         this.zpxxmes = res.data.data;
            //     });
            // },
            zpxx() {
                let params ={limit: 7}
                list(params,"RecruitmentInformation").then((res) => {
                    // console.log(res.data);
                    this.zpxxmes = res.data.data;
                });
            },
        },
    };
</script>
<style>
    .swiper {
        width: 100%;
        height: 100%;
    }
</style>

