<template>
    <div>
    <div class="banner2">
    </div>
    <div class="main center">
        <div class="main_tit">
            <h2>企业服务</h2>
            <img src="http://www.jxwlrl.com/images/jian.png">
            <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
            <ul>
                <li v-for="item in sortname" :key="item.id" :class="item.id==sort?'on':''">
                    <router-link :to="{path:'/TheEnterpriseService',query:{sort:item.id}}"> {{item.sortname}}</router-link>
                </li>
<!--                <li><a href="">服务外包</a></li>-->
<!--                <li><a href="">劳务派遣</a></li>-->
<!--                <li><a href="">人事代理</a></li>-->
<!--                <li><a href="">灵活用工</a></li>-->
<!--                <li><a href="">人才招聘</a></li>-->
<!--                <li><a href="">人才培训</a></li>-->
<!--                <li><a href="">代交社保</a></li>-->
            </ul>
        </div>
        <div class="fuwu_content">
            <div class="content_tit_type">
                <h1>{{mes.title}}</h1>
                <ul>
                    <!-- <li>作者：为力小编</li> -->
                    <li>发布时间：{{mes.time}}</li>
                    <li>浏览次数：{{mes.number}}</li>
                </ul>
            </div>
            <div class="content_con_type" v-html="mes.content">
            </div>
        </div>

    </div>
    </div>
</template>

<script>
    import {category, list,longlist} from '../api/aboutUs'
    import time from "../../public/time/time";
    import axios from "axios";

    export default {
        name: "TheEnterpriseService",
        data(){
            return{
                parentid: 3,
                sortname:[],
                sort:7,
                mes:[],
            }
        },
        filters: {
            todate: function (value) {
                return time.formtimestamp('d',value);
            },
            tomonth: function (value) {
                return time.formtimestamp("Y-m", value);
            },
        },
        watch: {
            $route() {
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this.message();
            }
        },
        methods:{
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {
                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
            message(){
                if (this.$route.query.sort){
                    this.sort=this.$route.query.sort;
                }
                // axios({
                //     url:"/api/receptionPhp/TheEnterpriseService/message.php",
                //     params: {sort:this.sort},
                // }).then((res)=>{
                //     // console.log(res.data.data);
                //     this.mes=res.data.data[0];
                // })
                let params = {sort:this.sort}
                list(params,'TheEnterpriseService').then((res)=>{
                    // console.log(res.data.data);
                    this.mes=res.data.data[0];
                })
            }
        },
        mounted() {
            this.getSortName();
            this.message();
        }
    }
</script>

<style scoped>

</style>