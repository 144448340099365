<template>
    <div>
    <div class="banner2">
    </div>
    <div class="main center">
        <div class="main_tit">
            <h2>联系我们</h2>
            <img src="http://www.jxwlrl.com/images/jian.png">
            <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
            <ul>
                <li v-for="item in sortname" :key="item.id" :class="sort==item.id?'on':''">
                <router-link :to="{path:'/ContactUs',query:{sort:item.id}}">{{item.sortname}}</router-link>
                </li>
<!--                <li><a href="">联系我们</a></li>-->
<!--                <li><a href="">在线留言</a></li>-->
            </ul>
        </div>
        <div class="lianxi_women" :style="{display:sort==51?'none':''}">
            <div class="dizhi">
                <h5>南昌为力人力资源服务有限公司</h5>
<!--                <p>联系电话： 0791-82203970</p>-->
                <p v-for="item in mes" :key="item.id">{{item.name}}:&nbsp;&nbsp;{{item.content}}</p>
<!--                <p>QQ：512763522</p>-->
<!--                <p>邮箱： 512763522@qq.com</p>-->
                <p>地址：江西省南昌市顺外路君嘉广场1号楼10楼</p>
            </div>
            <div class="map">
                <div>
                    <baidu-map v-bind:style="mapStyle" class="bm-view" ak="jlkjEg6SeIeri5YLwwpcxiS09zdU4xUC" :center="center" :zoom="zoom"
                               :scroll-wheel-zoom="true" @click="getClickInfo" @moving="syncCenterAndZoom"
                               @moveend="syncCenterAndZoom" @zoomend="syncCenterAndZoom">
                        <bm-view style="width: 100%; height:500px;"></bm-view>
                        <bm-marker :position="{lng: center.lng, lat: center.lat}" :dragging="true"
                                   animation="BMAP_ANIMATION_BOUNCE">
                        </bm-marker>
<!--                        <bm-control :offset="{width: '10px', height: '10px'}">-->
<!--&lt;!&ndash;                            <bm-auto-complete v-model="keyword" :sugStyle="{zIndex: 999999}">&ndash;&gt;-->
<!--&lt;!&ndash;                                <input type="text" placeholder="请输入搜索关键字" class="serachinput">&ndash;&gt;-->
<!--&lt;!&ndash;                            </bm-auto-complete>&ndash;&gt;-->
<!--                        </bm-control>-->
                        <bm-local-search :keyword="keyword" :auto-viewport="true"
                                         style="width:0px;height:0px;overflow: hidden;"></bm-local-search>
                    </baidu-map>
                </div>
            </div>
        </div>

        <div class="zxly clearFix" :style="{display:sort==52?'none':''}" >
            <div class="zxly_left">
                <h2>我们会做的更好<br>WE WILL DO BETTER</h2>
                <p>如果您对我们有什么建议、投诉、需求，可以通过<br>留言告诉我们，我们会在第一时间了解并及时与您联系。</p>
                <h1>THANKS !</h1>
            </div>
            <div class="zxly_right ">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"  class="demo-ruleForm">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码" prop="tel">
                        <el-input v-model="ruleForm.tel"></el-input>
                    </el-form-item>
                    <el-form-item label="您的留言" prop="content">
                        <el-input v-model="ruleForm.content" class="content"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="submitForm('ruleForm')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import axios from "axios";
    import time from "../../public/time/time";
    import {
        BaiduMap,
        BmControl,
        BmView,
        BmAutoComplete,
        BmLocalSearch,
        BmMarker
    } from 'vue-baidu-map';
    import common from "@/mixins/common";

    export default {
        components: {
            BaiduMap,
            BmControl,
            BmView,
            BmAutoComplete,
            BmLocalSearch,
            BmMarker
        },
        name: "ContactUs",
        mixins:[common],

        data(){
            return{
                classname:"OnlineMessage",
                parentid: 50,
                sortname:[],
                sort:51,
                mes:[],
                showMapComponent: this.value,
                keyword: '',
                mapStyle: {
                    width: '100%',
                    height: this.mapHeight + 'px'
                },
                center: {
                    lng: 115.944885,
                    lat: 28.668894
                },
                zoom: 15,
                hh:'ContactUs'
            }
        },
        filters: {
            todate: function (value) {
                return time.formtimestamp('d',value);
            },
            tomonth: function (value) {
                return time.formtimestamp("Y-m", value);
            },
        },
        watch: {
            $route() {
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this.message();
            },
            value: function(currentValue) {
                this.showMapComponent = currentValue;
                if (currentValue) {
                    this.keyword = ''
                }
            }
        },
        methods:{
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {
                let params ={parentid: this.parentid}
              category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
            // message(){
            //     if (this.$route.query.sort){
            //         this.sort=this.$route.query.sort;
            //     }
            //     axios({
            //         url:"/api/receptionPhp/ContactUs/message.php",
            //         // params: {sort:this.sort},
            //     }).then((res)=>{
            //         // console.log(res.data.data);
            //         this.mes=res.data.data;
            //     })
            // },

            message(){
                if (this.$route.query.sort){
                    this.sort=this.$route.query.sort;
                }
                let params ={}
                list(params,this.hh).then((res)=>{
                    // console.log(res.data.data);
                    this.mes=res.data.data;
                })
            },
            getClickInfo(e) {
                this.center.lng = e.point.lng
                this.center.lat = e.point.lat
            },
            syncCenterAndZoom(e) {
                const {
                    lng,
                    lat
                } = e.target.getCenter()
                this.center.lng = lng
                this.center.lat = lat
                this.zoom = e.target.getZoom()
            },
        },
        mounted() {
            this.getSortName();
            this.message();
            // this.init();
        }
    }
</script>

<style scoped>
    /*.serachinput {*/
    /*    width: 300px;*/
    /*    box-sizing: border-box;*/
    /*    padding: 9px;*/
    /*    border: 1px solid #dddee1;*/
    /*    line-height: 20px;*/
    /*    font-size: 16px;*/
    /*    height: 38px;*/
    /*    color: #333;*/
    /*    position: relative;*/
    /*    border-radius: 4px;*/
    /*    -webkit-box-shadow: #666 0px 0px 10px;*/
    /*    -moz-box-shadow: #666 0px 0px 10px;*/
    /*    box-shadow: #666 0px 0px 10px;*/
    /*}*/
</style>