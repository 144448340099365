<template>
    <div class="top">
        <div>
            <div class="left logo fl">
                <img src="../../public/images/top_logo.jpg" alt="logo"/>
            </div>
            <div class="right">
                <div class="tel fr">全国服务热线：<span>0791-82203969</span></div>
                <div class="search fr">
                    <!--                    <form action="http://www.wlrl.cn/news.php" method="GET" name="tijiao">-->
                    <!--                        <input-->
                    <!--                                type="text"-->
                    <!--                                placeholder="请输入搜索关键词"-->
                    <!--                                name="keywords"-->
                    <!--                                id="keywords"-->
                    <!--                        />-->

                    <!--                        <a href="javascript:void(0)"-->
                    <!--                        ><img src="http://www.jxwlrl.com/images/sou.png" alt="点击搜索"-->
                    <!--                        /></a>-->
                    <!--                    </form>-->

                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item prop="keywords">
                            <el-input v-model="ruleForm.keyword" placeholder="请输入搜索关键词"></el-input>
                            <router-link :to="{path:'/News',query:{keyword:this.ruleForm.keyword}}"><img
                                    src="http://www.jxwlrl.com/images/sou.png" alt="点击搜索"/></router-link>
                            <!--                            <a @click=""><img src="http://www.jxwlrl.com/images/sou.png" alt="点击搜索"/></a>-->
                        </el-form-item>
                    </el-form>

                </div>
                <div class="nav">
                    <ul class="clearFix">
                        <li>
                            <router-link to="/" :class="this.$route.path=='/'?'on':''">网站首页</router-link>
                        </li>
                        <li>
                            <router-link to="/AboutUs" :class="this.$route.path=='/AboutUs'?'on':''">关于我们</router-link>

                            <ul>
                                <li v-for="item in aboutus" :key="item.id">
                                    <router-link :to="{ path: '/AboutUs', query: { sort: item.id } }">
                                        {{item.sortname}}
                                    </router-link>
                                </li>
                            </ul>

                        </li>
                        <li>
                            <router-link to="/News" :class="this.$route.path=='/News'?'on':''">新闻资讯</router-link>
                            <ul>
                                <li v-for="item in news" :key="item.id">
                                    <router-link :to="{ path: '/News', query: { sort: item.id } }">{{item.sortname}}
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <router-link to="/TheEnterpriseService" :class="this.$route.path=='/TheEnterpriseService'?'on':''">企业服务</router-link>
                            <!--                            <a href="/theEnterpriseService">企业服务</a>-->
                            <ul>
                                <li v-for="item in theEnterpriseService" :key="item.id">
                                    <router-link :to="{ path: '/TheEnterpriseService', query: { sort: item.id } }">
                                        {{item.sortname}}
                                    </router-link>
                                </li>
                                <!--                                <li><a href="">公司动态</a></li>-->
                                <!--                                <li><a href="">行业快讯</a></li>-->
                                <!--                                <li><a href="">政策法规</a></li>-->
                                <!--                                <li><a href="">员工关系</a></li>-->
                                <!--                                <li><a href="">薪酬福利</a></li>-->
                                <!--                                <li><a href="">劳务维权</a></li>-->
                            </ul>
                        </li>
                        <li>
                            <router-link to="/TheStaffService" :class="this.$route.path=='/TheStaffService'?'on':''">员工服务</router-link>
                            <!--                            <a href="staffService.php">员工服务</a>-->
                            <ul>
                                <li v-for="item in staffService" :key="item.id">
                                    <router-link :to="{ path: '/TheStaffService', query: { sort: item.id } }">
                                        {{item.sortname}}
                                    </router-link>
                                </li>
                                <!--                                <li><a href="">公司动态</a></li>-->
                                <!--                                <li><a href="">行业快讯</a></li>-->
                                <!--                                <li><a href="">政策法规</a></li>-->
                                <!--                                <li><a href="">员工关系</a></li>-->
                                <!--                                <li><a href="">薪酬福利</a></li>-->
                                <!--                                <li><a href="">劳务维权</a></li>-->
                            </ul>
                        </li>
                        <li>
                            <router-link :to="{ path: '/Recruitment', query: { sort: 39 } }"  :class="this.$route.path=='/Recruitment'?'on':''">求职招聘</router-link>
                            <!--                            <a href="recruitment.php">求职招聘</a>-->
                            <ul>
                                <li v-for="item in recruitment" :key="item.id">
                                    <router-link :to="{ path: '/Recruitment', query: { sort: item.id } }">
                                        {{item.sortname}}
                                    </router-link>
                                </li>
                                <!--                                <li><a href="">行业快讯</a></li>-->
                                <!--                                <li><a href="">政策法规</a></li>-->
                                <!--                                <li><a href="">员工关系</a></li>-->
                                <!--                                <li><a href="">薪酬福利</a></li>-->
                                <!--                                <li><a href="">劳务维权</a></li>-->
                            </ul>
                        </li>
                        <li>
                            <!--                            <a href="contactUs.php">联系我们</a>-->
                            <router-link to="/ContactUs" :class="this.$route.path=='/ContactUs'?'on':''">联系我们</router-link>
                            <ul>
                                <li v-for="item in ContactUs" :key="item.id">
                                    <router-link :to="{ path: '/ContactUs', query: { sort: item.id } }">
                                        {{item.sortname}}
                                    </router-link>
                                </li>
                                <!--                                -->
                                <!--                                <li><a href="/ContactUs?sort=">联系我们</a></li>-->
                                <!--                                <li><a href="/ContactUs">在线留言</a></li>-->
                            </ul>
                        </li>
                        <li>
                            <router-link to="/DownLoad" :class="this.$route.path=='/DownLoad'?'on':''">下载中心</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {category, list} from '../api/aboutUs'
    import axios from "axios"
    import common from "@/mixins/common";

    export default {
        name: "myTop",
        mixins: [common],
        data() {
            return {
                aboutus: [],
                news: [],
                theEnterpriseService: [],
                staffService: [],
                recruitment: [],
                ContactUs: [],
                luyou:"/"
            };
        },
        mounted() {
            this.handlecategory();
        },
        watch: {
            $route() {
                if(this.$route.path){
                    this.luyou = this.$route.path
                }else{
                    this.luyou = "/"
                }
                // this.luyou = this.$route.path
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                // this.keyword = this.$route.query.keyword;

                // console.log(this.$route.path);

            }
        },
        methods: {
            // handlecategory() {
            //     axios({
            //         url: "/api/sort/sortclassreception.php",
            //         // responseType: 'arraybuffer',
            //     }).then((res) => {
            //         // console.log("类别打印", res.data);
            //         this.aboutus = res.data.AboutUs;
            //         this.news = res.data.New;
            //         this.theEnterpriseService = res.data.TheEnterpriseService;
            //         this.staffService = res.data.TheStaffService;
            //         this.recruitment = res.data.Recruitment;
            //         this.ContactUs = res.data.ContactUs;
            //     });
            // },

            handlecategory() {

                axios({
                    url: "http://www.wlrlphp.ganjiahandsome.online/sort/sortclassreception.php",
                    // responseType: 'arraybuffer',
                }).then((res) => {
                    // console.log("类别打印", res.data);
                    this.aboutus = res.data.AboutUs;
                    this.news = res.data.New;
                    this.theEnterpriseService = res.data.TheEnterpriseService;
                    this.staffService = res.data.TheStaffService;
                    this.recruitment = res.data.Recruitment;
                    this.ContactUs = res.data.ContactUs;
                });
            },
        },
    };
</script>

<style scoped>
</style>
