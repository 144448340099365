<template>

    <div>
        <div class="banner2">
        </div>
        <div class="main center">
            <div class="main_tit">
                <h2>求职招聘</h2>
                <img src="http://www.jxwlrl.com/images/jian.png">
                <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
                <ul>
                    <li v-for="item in sortname" :key="item.id" :class="sort==item.id?'on':''">
                        <router-link :to="{path:'/Recruitment',query:{sort:item.id}}">{{item.sortname}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="rec_info">
                <table>
                    <tbody>
                    <tr>
                        <td class="td1">职位名称</td>
                        <td class="td2" style="color: blue;">{{mes.jobtitle}}</td>
                        <td class="td3">招聘企业</td>
                        <td class="td4">{{mes.recruiters}}</td>
                    </tr>
                    <tr>
                        <td class="td1">工作地点</td>
                        <td class="td2">{{mes.workingplace}}</td>
                        <td class="td3">招聘人数</td>
                        <td class="td4">{{mes.number}}</td>
                    </tr>
                    <tr>
                        <td class="td1">学历要求</td>
                        <td class="td2">{{mes.degreerequired}}</td>
                        <td class="td3">薪资</td>
                        <td class="td4">{{mes.salary}}</td>
                    </tr>
                    <tr>
                        <td class="td1">岗位职责</td>
                        <td colspan="3" class="td5">
                           {{mes.responsibility}}
                        </td>
                    </tr>
                    <tr>
                        <td class="td1">职位要求</td>
                        <td colspan="3" class="td5" v-html="mes.jobrequirements">
                        </td>
                    </tr>
                    <tr>
                        <td class="td1">福利待遇</td>
                        <td colspan="3" class="td5">{{mes.welfaretreatment}}</td>
                    </tr>
                    </tbody>
                </table>
                <div style="clear:both;">
                </div>
                <div class="content_page">
                    <li v-if="promes">
                        上一篇：<router-link :to="{path:'/RecruitmentInformation',query: {sort:this.sort,id:this.promes.id}}">{{promes.jobtitle}}</router-link>
                    </li>
                    <li v-else> 上一篇：没有了</li>
                    <li v-if="nextmes">下一篇：<router-link :to="{path:'/RecruitmentInformation',query: {sort:this.sort,id:this.nextmes.id}}">{{nextmes.jobtitle}}</router-link></li>
                    <li v-else>下一篇：没有了</li>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {category, list,longlist} from '../api/aboutUs'
    import axios from "axios";
    import time from "../../public/time/time";

    export default {
        name: "RecruitmentInformation",
        data() {
            return {
                parentid: 32,
                sortname: [],
                id: "",
                sort: "",
                mes: [],
                promes: [],
                nextmes: []
            }
        },
        watch: {
            $route() {
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this.id = this.$route.query.id;//当路由变化时候需要监控id的变化
                // console.log(this.$route.query.id);
                this.message();
            }
        },
        methods: {
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {
                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
            message() {
                if (this.$route.query.id) {
                    this.sort = this.$route.query.sort
                    this.id = this.$route.query.id;//刚进去页面时候需要接到id并赋值
                    // console.log(this.$route.query.id)
                }
                // axios({
                //     url: "/api/receptionPhp/RecruitmentInformation/message.php",
                //     params: {id: this.id},
                // }).then((res) => {
                //     // console.log(res.data.data);
                //     this.mes = res.data.data;
                //     this.promes = res.data.pro;
                //     // console.log(res.data.pro);
                //     this.nextmes = res.data.next;
                // })
                let params = {id: this.id}
                list(params,'RecruitmentInformation').then((res) => {
                    // console.log(res.data.data);
                    this.mes = res.data.data;
                    this.promes = res.data.pro;
                    // console.log(res.data.pro);
                    this.nextmes = res.data.next;
                })
            }
        },
        mounted() {
            this.getSortName();
            this.message();
        }
    }
</script>

<style scoped>

</style>