<template>

        <div>
            <div class="banner2">
            </div>
            <div class="main center">
                <div class="main_tit">
                    <h2>员工服务</h2>
                    <img src="http://www.jxwlrl.com/images/jian.png">
                    <p>提供有价值的人力资源服务和解决方案，持续为客户创造价值</p>
                    <ul>
                        <li v-for="item in sortname" :key="item.id" :class="sort==item.id?'on':''">
                            <router-link :to="{path:'/News',query:{sort:item.id}}">{{item.sortname}}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="fuwu_content">
                    <div class="content_tit_type">
                        <h1>{{mes.title}}</h1>
                        <ul>
                            <!-- <li>作者：为力小编</li> -->
                            <li>发布时间：{{mes.time}}</li>
                            <li>浏览次数：{{mes.number}}</li>
                        </ul>
                    </div>
                    <div class="content_con_type" v-html="mes.content">
                    </div>
                    <div class="content_page">
                        <li v-if="promes">
                            上一篇：<router-link :to="{path:'/Newsdetails',query: {sort:this.sort,id:this.promes.id}}">{{promes.title}}</router-link>
                        </li>
                        <li v-else> 上一篇：没有了</li>
                        <li v-if="nextmes">下一篇：<router-link :to="{path:'/Newsdetails',query: {sort:this.sort,id:this.nextmes.id}}">{{nextmes.title}}</router-link></li>
                        <li v-else>下一篇：没有了</li>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

    import {category, list,longlist} from '../api/aboutUs'
    import axios from "axios";
    import time from "../../public/time/time";

    export default {
        name: "TheStaffServicedetails",
        data(){
            return{
                parentid: 2,
                sortname:[],
                id:"",
                sort:"",
                mes:[],
                promes:[],
                nextmes:[]
            }
        },
        watch: {
            $route() {
                // console.log(this.$route().query);
                // console.log("this.$route",this.$route.query);
                this.sort = this.$route.query.sort;
                this.id = this.$route.query.id;//当路由变化时候需要监控id的变化
                // console.log(this.$route.query.id);
                this.message();
            }
        },
        methods:{
            // getSortName() {
            //     axios({
            //         url: "/api/receptionPhp/AboutUs/AboutUs.php",
            //         params: {parentid: this.parentid}
            //     }).then((res) => {
            //         // console.log(res.data.data);
            //         this.sortname = res.data.data;
            //     })
            // },
            getSortName() {

                let params = {parentid: this.parentid}
                category(params).then((res) => {
                    // console.log(res.data.data);
                    this.sortname = res.data.data;
                })
            },
            message(){
                if (this.$route.query.sort){
                    this.sort=this.$route.query.sort;
                }if (this.$route.query.id){
                    this.id=this.$route.query.id;//刚进去页面时候需要接到id并赋值
                    console.log(this.$route.query.id)
                }
                // axios({
                //     url:"/api/receptionPhp/TheStaffService/message.php",
                //     params: {sort:this.sort,id:this.id},
                // }).then((res)=>{
                //     // console.log(res.data.data);
                //     this.mes=res.data.data[0];
                //     this.promes=res.data.pro[0];
                //     // console.log(res.data.pro);
                //     this.nextmes=res.data.next[0];
                // })
                let params = {sort:this.sort,id:this.id}
                list(params,'TheStaffService').then((res)=>{
                    // console.log(res.data.data);
                    this.mes=res.data.data[0];
                    this.promes=res.data.pro[0];
                    // console.log(res.data.pro);
                    this.nextmes=res.data.next[0];
                })
            }
        },
        mounted() {
            this.getSortName();
            this.message();
        }
    }
</script>

<style scoped>

</style>