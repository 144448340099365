import request from '../utils/request'
// import name from  './name'

const api={
    category:'/receptionPhp/AboutUs/AboutUs.php',
};
export  function category(params) {
    return request({
        url:api.category,
        params
    })
}
export  function list(params,name) {
    const hh={
        list:'/receptionPhp/'+name+'/message.php'
    };
    return request({
        url:hh.list,
        params
    })
}
export  function longlist(params,name,longname) {
    const hh={
        list:'/receptionPhp/'+name+'/'+longname
    };
    return request({
        url:hh.list,
        params
    })
}