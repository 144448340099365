import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUs from "@/views/AboutUs";
import News from "@/views/News";
import TheEnterpriseService from "@/views/TheEnterpriseService";
import TheStaffService from "@/views/TheStaffService";
import ContactUs from "@/views/ContactUs";
import DownLoad from "@/views/DownLoad";
import Newsdetails from "@/views/Newsdetails";
import TheStaffServicedetails from "@/views/TheStaffServicedetails";
import Recruitment from "@/views/Recruitment";
import RecruitmentInformation from "@/views/RecruitmentInformation";
import Recruitmentdetails from "@/views/Recruitmentdetails";

Vue.use(VueRouter)

const routes = [
  {
    path: '/Newsdetails',
    name: 'Newsdetails',
    component: Newsdetails
  },
  {
    path: '/Recruitmentdetails',
    name: 'Recruitmentdetails',
    component: Recruitmentdetails
  },
  {
    path: '/RecruitmentInformation',
    name: 'RecruitmentInformation',
    component: RecruitmentInformation
  },
  {
    path: '/Recruitment',
    name: 'Recruitment',
    component: Recruitment
  },
  {
    path: '/TheStaffServicedetails',
    name: 'TheStaffServicedetails',
    component: TheStaffServicedetails
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/DownLoad',
    name: 'DownLoa',
    component: DownLoad
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/TheEnterpriseService',
    name: 'TheEnterpriseService',
    component: TheEnterpriseService
  },
  {
    path: '/TheStaffService',
    name: 'TheStaffService',
    component: TheStaffService
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },

]

const router = new VueRouter({
  routes
})

export default router
